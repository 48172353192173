import { PaymentOptionType } from '@wix/ambassador-totals-calculator/http';
import { PaymentMethod } from '../../../types/types';

export const getPaymentOption = (
  selectedPaymentType: PaymentMethod,
  deposit: Maybe<number>,
) => {
  if (deposit) {
    return PaymentOptionType.DEPOSIT_ONLINE;
  } else if (selectedPaymentType === PaymentMethod.MEMBERSHIP) {
    return PaymentOptionType.MEMBERSHIP;
  } else {
    return selectedPaymentType === PaymentMethod.ONLINE
      ? PaymentOptionType.FULL_PAYMENT_ONLINE
      : PaymentOptionType.FULL_PAYMENT_OFFLINE;
  }
};
